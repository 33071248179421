import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import DateField from '@/shared/fields/date-field';

const label = (name) => i18n.t(`RESPONSIBILITY.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`RESPONSIBILITY.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    name: new StringField(
        'name',
        label('name'),
        { required: true},
        { placeholder: placeholder('name') }
    ),
    created_date: new DateField(
        'created_date',
        label('created_date'),
    ),
    updated_date: new DateField(
        'updated_date',
        label('updated_date'),
    ),
    description: new StringField(
        'description',
        label('description'),
        {required: false,},
        { placeholder: placeholder('description') }
    ),
};

export class ResponsibilityModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
